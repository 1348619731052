<script>
    import {getWithCredentials} from "~/common/fetchHelpers.js";
    import MessageBox from "~/components/MessageBox.svelte";
    import localizer from "~/common/i18n.js";
    import LinkButton from "~/components/LinkButton.svelte";

    export let legalEntity;
    export let expandButtonText;
    export let prioritizedPublicationDomain;
    let sites;
    let error;
    let showAll = false;

    function toggle() {
        showAll = !showAll;
    }

    async function loadSites() {
        return getWithCredentials(`/aid/profil/nettsteder?legal_entity=${legalEntity}${prioritizedPublicationDomain ? '&publication_domain='+prioritizedPublicationDomain : ''}`)
            .then(response => {
                if(!response.ok) {
                    return Promise.reject(new Error("Kunne ikke hente liste over nettsteder. Du kan forsøke å laste siden på nytt."));
                }
                return response.json();
            })
            .then((json) => {
                return json;
            })
    }

    const t = localizer({
        nb: {
            'paperName': 'Avisnavn',
            'site': 'Nettsted',
        },
        nn: {
            'paperName': 'Avisnamn',
            'site': 'Nettstad',
        }
    })
</script>

<div class="{showAll ? 'open' : 'closed'}">
    {#await loadSites() then sites}
        <table>
            <tr class="visually-hidden">
                <th>{t('paperName')}</th>
                <th>{t('site')}</th>
            </tr>
            {#each sites as site}
                <tr class="sites-list__site">
                    <td>{site.name?.full}</td>
                    <td><i>{site.domains?.main}</i></td>
                </tr>
            {/each}
        </table>
        {#if !showAll && sites.length > 5}
            <LinkButton on:click={toggle}>{expandButtonText || 'Se alle '+sites.length}</LinkButton>
        {/if}
    {:catch error}
        <MessageBox type="error">{error}</MessageBox>
    {/await}
</div>

<style>
    @import "../../common/colors.css";

    div {
        display: block;
        max-width: 500px;
        transition: 0.2s;
    }

    div table {
        width: 100%;
        margin-bottom: 5px;
    }

    div .sites-list__site,
    div.open .sites-list__site:nth-child(n + 6) {
        display: flex;
        flex-wrap: wrap;
        padding: 3px 0;
    }

    div .sites-list__site > td {
        flex: 1;
        min-width: 170px;
        display: inline-block;
    }

    div .sites-list__site:nth-child(odd) {
        background: var(--lightGray);
    }

    div.closed .sites-list__site:nth-child(n + 6) {
        display: none;
    }
</style>